import Opportunities from '../components/OpsChecklist/AddTask/Opportunities';
import { CHECKLIST_TYPE_ID } from '../constants/WstChecklist';
import OclAddOpportunity from '../pages/OclAddOpportunity';
import OclAddTask from '../pages/OclAddTask';
import OclAuditReport from '../pages/OclAuditReport';
import OclCloneTask from '../pages/OclCloneTask';
import OclEditOpportunity from '../pages/OclEditOpportunity';
import OclEditTask from '../pages/OclEditTask';
import OclTasks from '../pages/OclTasks';
import OpsOperatorView from '../pages/OpsOperatorView';
import WstAddTask from '../pages/weekendsetuptask/WstAddTask';
import WstCloneTask from '../pages/weekendsetuptask/WstCloneTask';
import WstEditTask from '../pages/weekendsetuptask/WstEditTask';
import WstTasks from '../pages/weekendsetuptask/WstTasks';
import IAppRoute from './AppRoute.type';
import routepaths from './routepaths';

const OpsChecklistRoutes: IAppRoute[] = [
  { path: routepaths.oclAddTask, element: <OclAddTask /> },
  { path: routepaths.oclAddOpps, element: <OclAddTask /> },
  { path: routepaths.oclOpportunities, element: <Opportunities /> },
  { path: routepaths.oclEditTask, element: <OclEditTask /> },
  { path: routepaths.oclCloneTask, element: <OclCloneTask /> },
  { path: routepaths.oclTasks, element: <OclTasks /> },
  {
    path: routepaths.gasOpsDayShift,
    element: <OclTasks key='gasOpsDayShift' />,
  },
  {
    path: routepaths.gasOpsNightShift,
    element: <OclTasks key='gasOpsNightShift' />,
  },
  {
    path: routepaths.wholesaleGasOps,
    element: <OclTasks key='wholesaleGasOps' />,
  },
  { path: routepaths.powerOps1, element: <OclTasks key='powerOps1' /> },
  { path: routepaths.powerOps2, element: <OclTasks key='powerOps2' /> },
  { path: routepaths.powerOps3, element: <OclTasks key='powerOps3' /> },
  { path: routepaths.powerOps4, element: <OclTasks key='powerOps4' /> },
  { path: routepaths.powerOpsSetUp, element: <OclTasks key='powerOpsSetUp' /> },
  { path: routepaths.powerDayShift, element: <OclTasks key='powerDayShift' /> },
  {
    path: routepaths.tradeControl,
    element: <OclTasks key='tradeControlOpsDayShift' />,
  },
  {
    path: routepaths.powerNightShift,
    element: <OclTasks key='powerNightShift' />,
  },
  { path: routepaths.powerAnalyst, element: <OclTasks key='powerAnalyst' /> },
  {
    path: routepaths.powerEarlyShift,
    element: <OclTasks key='powerEarlyShift' />,
  },
  {
    path: routepaths.powerLateShift,
    element: <OclTasks key='powerLateShift' />,
  },
  {
    path: routepaths.powerTradingShift,
    element: <OclTasks key='powerTradingShift' />,
  },
  {
    path: routepaths.renewableBioMethane,
    element: <OclTasks key='renewableBioMethane' />,
  },
  {
    path: routepaths.renewablePower,
    element: <OclTasks key='renewablePower' />,
  },
  {
    path: routepaths.renewableMonthly,
    element: <OclTasks key='renewableMonthly' />,
  },
  {
    path: routepaths.confirmationsDaily,
    element: <OclTasks key='confirmationsDaily' />,
  },
  {
    path: routepaths.renewableKrakowOps,
    element: <OclTasks key='renewableKrakowOps' />,
  },
  {
    path: routepaths.renewableEPTB,
    element: <OclTasks key='renewableEPTB' />,
  },
  { path: routepaths.oclAddOpportunities, element: <OclAddOpportunity /> },
  { path: routepaths.oclEditOpportunities, element: <OclEditOpportunity /> },

  { path: routepaths.oclOpsOperatorView, element: <OpsOperatorView /> },
  {
    path: routepaths.DealActualisationGasDaily,
    element: <OclTasks key='DealActualisationGasDaily' />,
  },

  {
    path: routepaths.wstTasks,
    element: (
      <WstTasks
        key={`compwstTasks_${CHECKLIST_TYPE_ID.WEEKDAY_SETUP_TASK}`}
        checklistTypeId={CHECKLIST_TYPE_ID.WEEKEND_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.wstAddTask,
    element: (
      <WstAddTask
        key={`compwstAddTask_${CHECKLIST_TYPE_ID.WEEKDAY_SETUP_TASK}`}
        checklistTypeId={CHECKLIST_TYPE_ID.WEEKEND_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.wstEditTask,
    element: (
      <WstEditTask
        key='compwstEditTask'
        checklistTypeId={CHECKLIST_TYPE_ID.WEEKEND_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.wstCloneTask,
    element: (
      <WstCloneTask
        key='compwstCloneTask'
        checklistTypeId={CHECKLIST_TYPE_ID.WEEKEND_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.wdstTasks,
    element: (
      <WstTasks
        key='compwdstTasks'
        checklistTypeId={CHECKLIST_TYPE_ID.WEEKDAY_CHECKLIST_POWERPORTFOLIO}
      />
    ),
  },
  {
    path: routepaths.wdstAddTask,
    element: (
      <WstAddTask
        key='compwdstAddTask'
        checklistTypeId={CHECKLIST_TYPE_ID.WEEKDAY_CHECKLIST_POWERPORTFOLIO}
      />
    ),
  },
  {
    path: routepaths.wdstEditTask,
    element: (
      <WstEditTask
        key='compwdstEditTask'
        checklistTypeId={CHECKLIST_TYPE_ID.WEEKDAY_CHECKLIST_POWERPORTFOLIO}
      />
    ),
  },
  {
    path: routepaths.wdstCloneTask,
    element: (
      <WstCloneTask
        key='compwdstCloneTask'
        checklistTypeId={CHECKLIST_TYPE_ID.WEEKDAY_CHECKLIST_POWERPORTFOLIO}
      />
    ),
  },
  {
    path: routepaths.mstTasks,
    element: (
      <WstTasks
        key='compmstTasks'
        checklistTypeId={CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.mstTasksGasOps,
    element: (
      <WstTasks
        key='compmstTasksGasOps'
        checklistTypeId={CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.mstTasksDealActualisationGas,
    element: (
      <WstTasks
        key='compmstTasksDealActualisationGas'
        checklistTypeId={CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.mstTasksDealActualisationPower,
    element: (
      <WstTasks
        key='compmstTasksDealActualisationPower'
        checklistTypeId={CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.mstTasksPowerOps,
    element: (
      <WstTasks
        key='compmstTasksPowerOps'
        checklistTypeId={CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.mstTasksConfirmations,
    element: (
      <WstTasks
        key='compmstTasksConfirmations'
        checklistTypeId={CHECKLIST_TYPE_ID.MONTHLY_CONFIRMATIONS}
      />
    ),
  },
  {
    path: routepaths.mstAddTask,
    element: (
      <WstAddTask
        key='compmstAddTask'
        checklistTypeId={CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.mstEditTask,
    element: (
      <WstEditTask
        key='compmstEditTask'
        checklistTypeId={CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK}
      />
    ),
  },
  {
    path: routepaths.mstCloneTask,
    element: (
      <WstCloneTask
        key='compmstCloneTask'
        checklistTypeId={CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK}
      />
    ),
  },
  { path: routepaths.oclAuditReport, element: <OclAuditReport /> },
];

export default OpsChecklistRoutes;
