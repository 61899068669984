export default {
  home: '/',
  login: '/login',
  authenticate: '/authenticate',
  unauthorized: '/unauthorized',
  unauthorizedCMT: '/unauthorized-application',
  forbidden: '/forbidden',
  error: '/error',
  logout: '/logout',
  getUserRole: '/get_user_role',
  dashboard: '/', // '/dashboard'
  scheduleRun: '/ser/schedule-run',
  netPosition: '/ser/net-position',
  netPositionTabulated: '/ser/net-position-tabulated',
  variable: '/ser/variable',

  oclTasks: '/ocl/tasks/:teamId/:shiftId',
  tradeControl: '/trade-control/tasks/:teamId/:shiftId',
  gasOpsDayShift: '/gasops-day-shift/tasks/:teamId/:shiftId',
  gasOpsNightShift: '/gasops-night-shift/tasks/:teamId/:shiftId',
  wholesaleGasOps: '/wholesale-gasops/tasks/:teamId/:shiftId',
  powerOps1: '/power-ops1/tasks/:teamId/:shiftId',
  powerOps2: '/power-ops2/tasks/:teamId/:shiftId',
  powerOps3: '/power-ops3/tasks/:teamId/:shiftId',
  powerOps4: '/power-ops4/tasks/:teamId/:shiftId',
  powerOpsSetUp: '/power-setup/tasks/:teamId/:shiftId',
  powerDayShift: '/power-day-shift/tasks/:teamId/:shiftId',
  powerNightShift: '/power-night-shift/tasks/:teamId/:shiftId',
  powerAnalyst: '/power-analyst/tasks/:teamId/:shiftId',
  powerEarlyShift: '/power-early-shift/tasks/:teamId/:shiftId',
  powerLateShift: '/power-late-shift/tasks/:teamId/:shiftId',
  powerTradingShift: '/power-trading-shift/tasks/:teamId/:shiftId',
  renewableBioMethane: '/renewable-biomethane/tasks/:teamId/:shiftId',
  renewablePower: '/renewable-power/tasks/:teamId/:shiftId',
  renewableMonthly: '/renewable-monthly/tasks/:teamId/:shiftId',
  confirmationsDaily: '/confirmations-daily/tasks/:teamId/:shiftId',
  renewableKrakowOps: '/renewable-krakowops/tasks/:teamId/:shiftId',
  renewableEPTB: '/renewable-EPTB/tasks/:teamId/:shiftId',
  DealActualisationGasDaily:
    '/deal-actualisation-gas-daily/tasks/:teamId/:shiftId',

  oclAddOpps: '/ocl/add-task/:id',
  oclAddTask: '/ocl/add-task',
  oclOpportunities: '/ocl/opportunities',
  oclEditTask: '/ocl/edit-task/:taskGroupKey',
  oclCloneTask: '/ocl/clone-task/:taskGroupKey',
  oclAuditReport: '/ocl/report/audit',
  oclOpsOperatorView: '/ocl/operatorView/:roleName',
  oclAddOpportunities: '/ocl/addOpportunities',
  oclEditOpportunities: '/ocl/editOpportunities',
  wstTasks: '/west/tasks/:teamId/:shiftId',
  wstAddTask: '/west/add-task/:teamId',
  wstEditTask: '/west/edit-task/:taskGroupKey/:teamId',
  wstCloneTask: '/west/clone-task/:taskGroupKey/:teamId',
  wdstTasks: '/wdst/tasks/:teamId/:shiftId',
  wdstAddTask: '/wdst/add-task/:teamId',
  wdstEditTask: '/wdst/edit-task/:taskGroupKey/:teamId',
  wdstCloneTask: '/wdst/clone-task/:taskGroupKey/:teamId',

  mstTasks: '/mst/tasks/:teamId/:shiftId',
  mstTasksGasOps: '/mst-gasops/tasks/:teamId/:shiftId',
  mstTasksDealActualisationGas: '/mst-gas/tasks/:teamId/:shiftId',
  mstTasksDealActualisationPower: '/mst-power/tasks/:teamId/:shiftId',
  mstTasksPowerOps: '/mst-powerops/tasks/:teamId/:shiftId',
  mstAddTask: '/mst/add-task/:checklistTypeId/:teamId',
  mstEditTask: '/mst/edit-task/:checklistTypeId/:taskGroupKey/:teamId',
  mstCloneTask: '/mst/clone-task/:checklistTypeId/:taskGroupKey/:teamId',
  mstTasksConfirmations: '/mst-confirmations/tasks/:teamId/:shiftId',

  chartsTradedGasVolume: 'charts/traded-gas-volume/:type',
  cmtApplicationStatus: 'application-status/:type',

  chartsCapacityVolumeBooked: 'charts/capacity-volume-booked/:type',
  chartsVolumeImbalance: 'charts/volume-imbalance/:type',

  cnCalcConvert: '/cncalc/convert',
  cnCalcFormulaBuilder: '/cncalc/formula-builder',
  cnCalcEditFormula: '/cncalc/edit-formula/:id',
  weightedAverageCalculator: '/weighted-average',

  credstoreViewCredentials: '/credstore/view-credentials',
  credstoreEditCredentials: '/credstore/edit-credential/:id',
  credstoreAddCredentials: '/credstore/add-credential',

  tradePriceList: '/tradeprice/price-list/:market',

  prismaCalendar: 'prisma-calendar',
  auctionDetails: 'prisma/:page',

  contactListView: '/contact-list/:market/view',
  contactListAdd: '/contact-list/:market/add',
  contactListEdit: '/contact-list/:market/edit/:id',

  positionHandoverPositionsCard: '/position-handover/positions-card',
  positionHandoverMySteers: '/position-handover/my-steers',
  positionHandoverSummary: '/position-handover/summary',
  positionHandoverSettings: '/position-handover/settings',
  positionHandoverOperatorView: '/position-handover/operator-view',

  sena: '/sena',
  senaSettings: '/sena-settings/:type',
  epsqCalculator: '/epsq-calculator',
  capacityTariff: '/capacity-tariff',
  capacityTariffAdd: '/capacity-tariff-add',

  pnlDashboardView: '/pnl-dashboard/view',
  pnlDashboardInput: '/pnl-dashboard/input',

  senaPasswordhubViewCredentials: '/sena-password-hub/view-credentials',
  senaPasswordhubEditCredentials: '/sena-password-hub/edit-credential/:id',
  senaPasswordhubAddCredentials: '/sena-password-hub/add-credential',

  // GTO Logs
  addAttachments: '/gto/attachments',
  gtoLogs: '/gto/viewLogs',

  // Power Shift Logs
  powerShiftLogs: '/power-shift/viewLogs',
  ptoAttachments: '/power-shift/Attachments/:logId/:date',

  // UserAdministration
  userAdministrationApp: '/user-admin/app',
  userAdministrationRoles: '/user-admin/roles',
  userAdministrationUser: '/user-admin/user',
  userAdministrationAddUser: '/user-admin/add',

  // UK Field Nomination
  fieldNomination: '/field-nomination',
  terminalMapping: '/terminal-mapping',

  // storage Portfolio
  storagePortfolioDashboard: '/storage-portfolio/dashboard',
  dealParameters: '/storage-portfolio/deal-parameters',
  facilityOverview: '/storage-portfolio/facility-overview',
  facilityAttachment: '/storage-portfolio/facility-attachment/:id',
  dynamicTable: '/storage-portfolio/dynamic-table',
  facilityCompare: '/storage-portfolio/facility-compare',

  dealFacilityAttachment: '/deal/facility-attachment/:dealId',

  // beach swaps
  beachSwaps: '/beach-swaps',
  coastKeyMapper: '/beach-swaps/coast-key-mapper',

  // IBT
  ibtGas: '/ibt/gas',
  ibtPower: '/ibt/power',

  // Reconcile

  reconcile: '/reconcile',
};

export const dynamicPath = {
  contactListViewCounterparty: '/contact-list/counterparty/view',
  contactListAddCounterparty: '/contact-list/counterparty/add',
  contactListEditCounterparty: (id: number) =>
    `/contact-list/counterparty/edit/${id}`,

  positionHandoverEdit: (id: number) => `/position-handover/edit-trades/${id}`,
  positionHandoverClone: (id: number) =>
    `/position-handover/clone-trades/${id}`,

  wstTasks: (teamId: number, shiftId: number) =>
    `/west/tasks/${teamId}/${shiftId}`,
  wstAddTask: (teamId?: number) => `/west/add-task/${teamId}`,
  wstEditTask: (taskGroupKey: string, teamId: number) =>
    `/west/edit-task/${taskGroupKey}/${teamId}`,
  wstCloneTask: (taskGroupKey: string, teamId: number) =>
    `/west/clone-task/${taskGroupKey}/${teamId}`,
  wdstTasks: (teamId: number, shiftId: number) =>
    `/wdst/tasks/${teamId}/${shiftId}`,
  wdstAddTask: (teamId?: number) => `/wdst/add-task/${teamId}`,
  wdstEditTask: (taskGroupKey: string, teamId: number) =>
    `/wdst/edit-task/${taskGroupKey}/${teamId}`,
  wdstCloneTask: (taskGroupKey: string, teamId: number) =>
    `/wdst/clone-task/${taskGroupKey}/${teamId}`,
  mstTasks: (teamId: number, shiftId: number) =>
    `/mst/tasks/${teamId}/${shiftId}`,
  mstEditTask: (
    checklistTypeId: number,
    taskGroupKey: string,
    teamId: number
  ) => `/mst/edit-task/${checklistTypeId}/${taskGroupKey}/${teamId}`,
  mstCloneTask: (
    checklistTypeId: number,
    taskGroupKey: string,
    teamId: number
  ) => `/mst/clone-task/${checklistTypeId}/${taskGroupKey}/${teamId}`,
  mstAddTask: (checklistTypeId: number, teamId?: number) =>
    `/mst/add-task/${checklistTypeId}/${teamId}`,

  oclTasks: (teamId: number, shiftId: number) =>
    `/ocl/tasks/${teamId}/${shiftId}`,

  tradePriceList: (market: number) => `/tradeprice/price-list/${market}`,
};
